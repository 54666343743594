@import "~app/mixins";

.breadcrumbs {
  display: flex;
  margin: -1px -1px 0;
  padding: 0;
  font-size: 0;
  border: 1px solid transparent;
  background-color: $color-white;

  @include primary-border-color();
}

.link {
  position: relative;
  flex: 1;
  line-height: 48px;
  padding-left: 25px;
  color: $color-blue-light;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  list-style-type: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    border-style: solid;
    border-width: 24px 0 24px 25px;
    border-color: transparent;
  }

  &:before {
    @include primary-border-left-color();
  }

  &:after {
    left: 0;
    border-left-color: $color-white;
  }

  &:first-child {
    padding: 0;

    &:before,
    &:after {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: $color-blue-light;
    display: inline-block;
    width: 100%;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.link.past {
  z-index: 1;
  background-color: $color-light-blue;

  &:after {
    border-left-color: $color-light-blue;
  }
}

.link.active {
  color: $color-white;

  @include primary-background-color();

  a {
    color: $color-white;
  }

  &:after {
    border-left-color: $color-light-blue;
  }

  + .link {
    &:after {
      @include primary-border-left-color();
    }
  }
}

.link.disabled {
  background-color: #ddd;
  color: $color-grey;

  + .link {
    &:after {
      border-left-color: #ddd;
    }
  }
}
